.step {
    color: white;
    width: 40px;
    height: 40px;
    font-size: 8px;
    background-color:#f3a95c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .step.completed {
    background-color: #569f51;
    
  }
  