

.App {
  
  background-color:rgb(234, 234, 234);
  
}



.main-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 100vh;
  background-color: rgb(234, 234, 234) ;
  width: 100%;
  
}

.sidebar-container {
  width: 20%;
}

.navbar-outlet-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: rgb(234, 234, 234);
}

.main-layout-outlet-container {
 min-height: 82vh;
 width: 100%;
}

.main-layout-footer-container {
  width: 100%;
 }


.login-container {

  background-size: cover;
  margin-left: 0%;
  margin-right: 0%;

  padding-top: 2Vh;
  height: 100vh;
  background-image: url(./images/pinniTagsBG2.jpg);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.register-container {

  align-items: center;


}

.otp-button {

  width: 100%;
  background-color: #569f51;
  font-size: 12px;
  color: #fefefe;
}

.otp-button:hover {

  width: 100%;
  background-color: #1b7614;
  font-size: 12px;
  color: #fefefe;
}

.login-button {
  font-size: 12px;
  background-color: #569f51;
  width: 100%;
  color: #fefefe;
}

.login-button:hover {
  font-size: 12px;
  background-color: #2a7824;
  width: 100%;
  color: #fefefe;
}







.login-card {
  background-color: #fefefe;
  padding-top: 1%;
  padding-left: 5%;
  padding-right: 5%;
  max-height: 100vh;
  min-height: 90%;
  font-size: 12px;
  border-radius: 0px;
  width:32%;
  overflow-y: hidden;
}

.register-card {
  padding-top: 10%;
  height: 1000%;


}

.link {
  color: #2a7824;
  margin-bottom: 0px;
}


.dashboard-container {
  padding: 5%;
  padding-top: 0%;

}

.login-form {
  display: block;
  font-size: 12px;

}

.logo {
  padding-bottom: 2%;
}

.carousel-container {
  min-height: 100vh;
}

.carousel {
  min-height: 50vh;
  background-color: rgb(245, 249, 245);
}

.carousel-text {
  min-height: 40vh;
  background-color: green;
  color: orange;
  text-align: center;
}

.carousel-logo {
  min-height: 10vh;
  margin-bottom: 10vh;
  padding: 10%;
}

.form-container {
  max-height: 100vh;
  /* padding-top: 4%; */
  padding-left: 5%;
  padding-right: 5%;
  overflow-y: hidden;
  font-size: 12px;
  padding-bottom: 10%;
  width: 50%;


}

.form-button {
  margin: 1%;
  color: #fefefe;
}

.form-back:hover {
  background-color: #a4a3a2;
  color: #fefefe;
}

.form-next:hover {
  background-color: #a4a3a2;
  color: #fefefe;
}

.form-progressbar {
  color: green;
}

.form-next {
  background-color: #999896;
  width: 40px;
  height: 40px;
}

.form-reg-submit {
  background-color: #2a7824;

}

.form-reg-submit:hover {
  background-color: #2e8028;
  color: white;
}



.form-back {
  background-color: #999896;
  width: 40px;
  height: 40px;
}

.form-image-col {
  max-width: 50%;
  padding: 10%;

}

.form-image {
  max-width: 60%;

}

.phone-input-login {
  padding-bottom: 2%;
}

.terms-card {
  background-color: #fefefe;
}

.login-inputs {
  margin-left: 0.5%;
  width: 99%;
  justify-content: center;
}

.verify-container {
  min-height: 70vh;
  padding: 10%;
  color: black;
  font-size: 12px;
  width: 100%;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 0%;

}

.main-verify-container {
  padding-top: 0px;
  background-image: url(./images/pinniTagsBG.jpg);
  height: 100vh;
  background-size: cover;
}

.verify-input {
  margin-bottom: 3%;
}

.yes-button-login {
  margin-right: 5%;
  background-color: #569f51;
  min-width: 80px;
  font-size: 12px;
  color: #fefefe;
}

.yes-button-login:hover {
  margin-right: 5%;
  background-color: #2a7824;
  min-width: 80px;
  font-size: 12px;
  color: #fefefe;
}

.no-button-login {
  background-color: #f4941c;
  min-width: 80px;
  font-size: 12px;
  color: #fefefe;
}

.no-button-login:hover {
  background-color: #f1961e;
  min-width: 80px;
  font-size: 12px;
  color: #fefefe;
}

.switch-row {
  width: 100%;
  justify-content: center;

}

.home-text {
  margin-bottom: 3%;
}

.terms {
  padding-left: 8%;
}

.terms-link {
  color: #569f51;
}

.other-input {
  margin-top: 10px;
  font-size: 12px;

}

.formImage-container {
  overflow-y: hidden;
  background-size: cover;
  width: 50%;
  
  align-items: center;
  object-fit: cover;

  padding: 0px;
}

.main-form-container {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  background-color: #fefefe;
  border-color: #569f51;
  padding-right: 0%;
  padding-left: 0%;
  border-radius: 0px;
  width: 70%;
  max-height: 95vh;




}

.main-main-form-container {
 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  background-image: url(./images/pinniTagsBG.jpg);
  background-size: cover;


}

.input-box {
  font-size: 12px;
  margin-bottom: 1px;
  margin-top: 5%;
}

.input-box-ticket {
  font-size: 12px;
  margin-bottom: 1px;
  margin-top: 1px;
}

.input-box-identity {
  font-size: 12px;
  margin-bottom: 17px;
  margin-top: 0px;
}

.input-box-events {
  font-size: 12px;
  margin-top: 0px;
}

.input-box-invoice-form {
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 0px;
}

.input-box-view {
  font-size: 12px;
  margin-bottom: 1px;
  margin-top: 1px;
  width: 90%;
  border: 1px solid #1b7614;

}

.input-box-login {
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.terms-card {
  margin-top: 2px;
  height: 4%;
  font-size: 12px;
  width: 100%;
}

.form-image {
  width: 500px;
  margin-bottom: 12%;

}

.form-selector {
  font-size: 12px;
}

.form-step {
  margin-top: 100px;
}



.terms-card-login {
  margin-top: 0px;
  height: 2%;
  background-color: #569f51;
}

.form-area-image {
  
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width:100%;
  margin-bottom: 20px;
  margin-top: 10px;
  padding-left:20% ;
  padding-right:20% ;
}

.step4-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -18px;
}

.step2-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}


.step4-terms {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  font-size: 10px;


}

.step4-typer {
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;

}

.linkgreen {
  color: #569f51;
}

.login2-image {



  max-width: 100%;
  padding-left: 15%;
  padding-right: 15%;

  justify-content: center;
}

.email-verification-image {



  max-width: 100%;
  padding-left: 10%;
  padding-right: 10%;

  justify-content: center;
}

.payment-logo {



  max-width: 100%;
  padding-left: 20%;
  padding-right: 20%;

  justify-content: center;
}



.tcp {
  font-size: 11px;
}

.back-wrapper1 {
  width: 75%;
  height: 9%;
  transform: skew(0deg, 20deg);
  position: fixed;
  margin-top: 15%;
  background-color: #4c9c44;
}

.back-wrapper2 {
  width: 75%;
  height: 9%;
  transform: skew(0deg, 20deg);
  position: fixed;
  margin-top: 15%;
  background-color: #f4941c;
  margin-left: 10%;
}

.back-wrapper3 {
  width: 75%;
  height: 9%;
  transform: skew(0deg, 20deg);
  position: fixed;
  margin-top: 15%;
  background-color: #84c444;
  margin-left: 20%;

}

.back-div {
  position: fixed;
  z-index: -1;
}

.sidebar {
  height: 100%;
  background-color: #f8f9fa;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.switch-wrapper {


  padding-left: 13%;


}

.ticket-Container {
  max-width: 100%;
  margin-top: 2%;
  font-size: 12px;
  min-height: 100vh;
}

.tickets-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.ticket-button {
  font-size: 10px;
  margin: 2px;
}

.view-ticket-button {
  font-size: 12px;
  margin: 2px;
  color: #007bff;

}

.username-column {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 20%;

  padding-right: 1%;
}

.statuses {
  display: flex;
  flex-direction: row;

}

.in-progress {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.five-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;

  width: 80%;
  padding-left: 22%;
}

.verify-button {
  font-size: 10px;
  margin: 5px;
}

.back-wrap1 {
  width: 75%;
  height: 9%;
  transform: skew(0deg, 20deg);
  position: fixed;
  margin-top: 15%;
  background-color: #4c9c44;
}

.back-wrap2 {
  width: 75%;
  height: 9%;
  transform: skew(0deg, 20deg);
  position: fixed;
  margin-top: 15%;
  background-color: #f4941c;
  margin-left: 10%;

}

.back-wrap3 {
  width: 75%;
  height: 9%;
  transform: skew(0deg, 20deg);
  position: fixed;
  margin-top: 15%;
  background-color: #84c444;
  margin-left: 20%;

}

.meetings-container {
  max-width: 100%;
  margin-top: 2%;
  font-size: 12px;
}

.mass-button {
  font-size: 10px;
  margin: 2px;
}

.mass-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;


}


.schedule-button {
  font-size: 10px;
  margin: 4px;
}


.meetingsdash-container {
  max-width: 100%;
  margin-top: 2%;
  font-size: 12px;
  min-height: 100vh;
}

.card-image {
  height: 10vh;
  width: 100%;
}

.dash-card {
  font-size: 12px;

  height: 28vh;
  background-color: #f3a95c;
  text-align: center;
}

.identity-dash-card {
  font-size: 12px;
  height: 16vh;
  background-color: #fefefe;
  text-align: center;
  border: 1px solid gray;
  max-width: 150px;


}

.identity-dash-card-inactive {
  font-size: 12px;
  height: 16vh;
  background-color: #f2f2f2;
  text-align: center;
  border: 1px solid #ddd;
  max-width: 150px;
  color: #aaa;


}


.dashMeeting-button {
  font-size: 10px;
  margin: 0px;

}

.tabs {
  color: #2a7824;
  font-size: 13px;
}

.pagination {

  font-size: 12px;
}

.ticket-pop-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10%;
}

.ticket-popup-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  font-size: 10px;



}

.ticket-popup-header span {
  font-weight: bold;
  font-size: 12px;
}

.chat-column {
  width: 80%;
  padding-left: 0%;
}

.message-row {
  margin-bottom: 5%;
}

.message-area-tickets {
  height: 43vh;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 12px;
}

.my-bubble {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.their-bubble {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.my-card {
  background-color: #2a7824;
  color: #fff;
  border-color: #2a7824;
  border-radius: 20px;
  margin-right: 10px;
}

.their-card {
  background-color: #5E5E5F;
  color: #fff;
  border-color: #5E5E5F;
  border-radius: 20px;
  margin-left: 10px;
}

.identity-form-button {
  font-size: 10px;
  margin: 2px;
}

.identity-form-button-update {
  font-size: 10px;
  margin: 2px;
  max-width: 150px;

}

.identity-registration-logo {



  max-width: 100%;
  padding-left: 40%;
  padding-right: 40%;

  justify-content: center;
}

.cart-items {
  border-bottom: 1px solid gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.lpo-inputs {
  font-size: 8px;
  max-width: 50%;
}

.lpo-form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.billing-company p {
  margin: 0px;
  width: 200px;



}

.billed-company p {
  margin: 0px
}

.invoice-info p {
  margin: 0px
}

.IPD-main-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.IPD-main-column-1 {
  width: 100%;

}

.IPD-main-column-2 {
  width: 20%;
}

.IPD-container {
  width: 100%;
}

.user-image {
  width: 20px;
  height: 20px;
  object-fit: cover;
  background-color: #4d4848;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.Loggedin-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 200px;
  padding-bottom: 10px;
  padding-top: 5px;
}

.Loggedin-content {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  height: 15px;
  text-decoration: none;
  color: black;

}

.Notification-content {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  height: 10px;
  text-align: center;
  justify-content: start;
  min-width: 300px;
}


.identity-card {
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1000px;
  font-family: Arial, sans-serif;
  background-color: #4e73df;
  /* set the background color to blue */
  background-image: url(./images/brushed-alum.png );
  /* set the background texture */
  min-height: 45vh;
}

.identity-card-demo-default {
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 390px;
  font-family: Arial, sans-serif;
  background-color: #4e73df;
  /* set the background color to blue */
  background-image: url(./images/brushed-alum.png );
  /* set the background texture */
  min-height: 40vh;
}

.identity-card-demo-checkered {
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 390px;
  font-family: Arial, sans-serif;
  background-color: #4e73df;
  /* set the background color to blue */
  background-image: url(./images/checkered-pattern.png);
  /* set the background texture */
  min-height: 40vh;
}

.identity-card-demo-cube {
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 390px;
  font-family: Arial, sans-serif;
  background-color: #4e73df;
  /* set the background color to blue */
  background-image: url(./images/cubes.png  );
  /* set the background texture */
  min-height: 40vh;
}

.identity-card .card-img-top {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 60%;
  width: 100%;
}

.identity-card-demo-image {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 100px;
  width: 100px;
}

.identity-card .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #5E5E5F;
}

.identity-card .card-text {
  font-size: 12px;
  line-height: 2;
}

.identity-card .card-text strong {
  font-weight: bold;
}

.identity-card .row {
  margin-right: 0;
  margin-left: 0;
}

.card-operations-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}

.card-operations-card {
  width: 30%;
}

.card-operations-container {
  min-height: 100vh;
  padding-top: 40px;
}

.card-operations-card:hover {
  cursor: pointer;
}

.card-design-container {

  font-size: 12px;
  padding-top: 40px;

}


.invoice-create-container {

  font-size: 12px;
  padding-top: 40px;
  margin-bottom: 20px;
}

.card-design-main-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  padding: 40px;
  min-height: 90vh;
}

.card-design-div1 {
  width: 30%;

}

.card-design-div2 {
  width: 60%;
  margin-left: 10px;
}

.registration-photo {
  width: 100%;
  height: 100%;

}

.registration-form-top-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sectioncompleted {
  color: #1b7614;
}

.payment-card-mtn-image {



  max-width: 100%;
  padding-left: 42%;
  padding-right: 42%;


  justify-content: center;
}

.payment-card-visa-image {



  max-width: 100%;
  padding-left: 35%;
  padding-right: 35%;


  justify-content: center;
}



.payment-card-container {
  margin: 0px;
  height: 72vh;


}

.identity-adds-on {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.identity-adds-on-less {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}


.footer {
  border-top: 1px solid #f2f2f2;
  padding: 10px;
  margin-top: auto;
}

.document-card {
  min-width:200px;
  cursor: pointer;
}


.document-card-inactive {
  min-width:200px;
  cursor: pointer;
  color: #aaa;
  background-color: #f2f2f2;
}




.documents-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-icons {
  margin-left: 2rem;
  margin-right: 1rem;
  font-size: 15px;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.id-image {



  max-width: 100%;


  justify-content: center;
}

.linked-settings {
  background-color: #f5f5f5;
  height: 79vh;
  overflow-y: scroll;
}

.qrcode-container {
  width: 794px;
  /* 210mm converted to pixels */
  height: 1123px;
  /* 297mm converted to pixels */
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;

}

.qrcode {
  text-align: center;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.tag-logo {
  max-width: 80px;
  margin-top: 1px;
  margin-bottom: 0px;
}

.tag-Image {
  max-width: 120px;
  margin-top: 8px;
  margin-bottom: 0px;
}

.label-logo {
  max-width: 60px;
  margin-top: 1px;
  margin-bottom: 0px;
}

.label-logo-brother {

  margin-top: 0px;
  margin-bottom: 0px;
}

.label-Image {
  max-width: 110px;
  margin-top: 3px;
  margin-bottom: 0px;
}

.label-Image-aztec-datamatrix {
  max-width: 90px;
  margin-top: 10px;
  margin-bottom: 0px;

}

.tag-types {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-height: 30vh;
}

.A4footer {
  border-bottom: 1px solid #f2f2f2;

  margin-top: 2px;

}

.taglabel-container {
  width: 193px;
  /* 210mm converted to pixels */
  height: 95px;
  /* 297mm converted to pixels */
  background-color: white;
  padding: 20px;
  padding-top: 0px;
  box-sizing: border-box;
  margin: 0 auto;

}

.taglabel-container-qrcode {
  height: 193px;
  width: 95px;
  background-color: white;
  padding: 20px;
  padding-top: 0px;
  box-sizing: border-box;
  margin: 0 auto;

}

.taglabel-container-NELD {
  height: 90px;
  width: 400px;
  background-color: rgb(252, 250, 250);
  padding: 0px;
  padding-top: 0px;
  box-sizing: border-box;
  margin: 0 auto;


}



.label-code {
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.Custom-label-code {
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  font-size: 12px;
  font-weight: bold;
  background-color: #fcfdfc;
  margin-left: 0px;
  margin-right: 0px;
}

.label-info {
  margin-bottom: 0px;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  width: 100%
}

.label-info-brother-strip {
  margin-bottom: 0px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  width: 100%
}

.label-info-brother-strip-logo {
  margin-bottom: 0px;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  width: 100%
}

.label-info-QR {
  margin-bottom: 0px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  width: 100%
}

.ticket-input {
  width: 390px;
}

.assets-outlet {
  background-color: white;
  padding: 100px;
  height: 90vh;
}

.assets-outlet-inner {
  background-color: transparent;
  padding: 0px;
  height: 90vh;
}

.neldlogo {
  width: 20%;
}

.neldAgenda {
  width: 80%
}

.neldAgendaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.neldTheme {
  width: 400px;
  text-align: center;
  font-size: 14px;
}

.eventAgendaContainer {
  padding: 50px;
  padding-top: 0%;
  min-height: 100vh;
}

.neldRegForm {
  margin-top: 10vh;
}

.neldpinnitagsLogo {
  width: 20%
}

.eventFormLabels {
  width: 25%;
  margin-top: 25px;
}

.FormLabels {
  width: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.FormLabelsModel {
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.ant-btn:hover {

  border-color: #569f51;
  color: #569f51;
}

.ant-btn {
  font-size: 10px;
  border: 1px solid #569f51
}


.pass-access-zones {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-control:focus {
  border-color: #569f51;
  box-shadow: 0 0 0 0.1rem rgba(6, 204, 16, 0.25);
  /* Optional: Add a box shadow */
}


.form-select:focus {
  border-color: #569f51;
  box-shadow: 0 0 0 0.1rem rgba(6, 204, 16, 0.25);
  /* Optional: Add a box shadow */
}

.billing-sidebar-item {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin: 10px;
  padding: 5px;
  
}

.billing-sidebar-item-active {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin: 10px;
  padding: 5px;
  border-radius: 0px;
  border-left: 2px solid rgb(35, 129, 59);
}

.billing-sidebar-item:hover {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 10px;
  background-color: #5E5E5F;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.billing-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
  

}



.billing-sidebar-column {
  border-right: 1px solid gray;
  min-height: 100vh;
}

.pass-template-container{
  width: 80%
}

.credits-value{
  border-bottom: 1px solid gray;
}

.credits-overview{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.credits-pie{
  width:40% ;
}

.credits-details{
  width: 60%;
}

.bills-summary-header{
  border-bottom: 1px solid gray;
}

.bill-summary-dates{
  border-left: 1px solid gray;
}

/* .bugandaPassTheme{
  transform: rotate(180deg);
} */



@keyframes myAnimation {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rainbow {
  0% { color: red; }
  25% { color: rgb(28, 128, 0);}
  50% { color: orange; }
  75% { color: rgb(28, 128, 0); }
  100% { color: orange;  }
}

.animatedText {
  animation: myAnimation 2s ease-in-out forwards;
}

.rainbowText {
  animation: rainbow 5s ease-in-out forwards;
}


.home-container {
  min-height: 100vh;
  font-size: 12px;
  max-width: 100%;
  
}

.home-card {
  border-radius: 0;
  border: 0;
}


.dash-concepts-container{
 
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  justify-content:start;
  max-width: 910px;
  min-width: 100%;
}

.dash-concepts-container::-webkit-scrollbar {
  width: 0; /* Adjust as needed */
}

.dash-concepts-container::-webkit-scrollbar-thumb {
  background-color: transparent; /* Hide the thumb */
}

.dash-concept{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.dash-concept:hover{
  border: 1px solid gray;
}

.dash-concept-image{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80px;
}

.dash-concept-name{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.image-preview-events-form{
  max-width: 255px;
  min-width: 250px;
}

.transparent-loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background:none;
  text-align: center;
}

.scrollable-checkbox-div{
 
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  justify-content:start;
  max-width: 640px;
  min-width: 100%;
}

/* cropper styles below */

.cropped-image {
  height: 600px;
  width: auto;
}

.cropped-image-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 400px;
}

._coverImage-holder  {
  padding: 25px 40px;
  background-color:black;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}


.crop-app-container {
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin-top: 25px; */
  align-items: center;
  width:100%;
  height:100%;
}

.crop-container {
  height: 600px;
  width: 600px;
}

.controls {
  display: flex;
  flex-direction: column;
  width: 600px;
  position: absolute;
  bottom: -15px;
}

/* cropper styles above */


/* ticket and pass registration page css above below */

.event-Registration-container {
  position: absolute;
  top: 0;
  padding: 50px;
  padding-top: 0%;
  min-height: 100vh;
  left: 0;
  right: 0;
  background-image: url(./images/pinniTagsBG.jpg);
  background-size:cover;
  max-width: 100%;
}

.event-Registration-body{
  margin-top: 10vh;
  background-color:rgba(233, 235, 235, 0.8);
  min-height: 90vh;
  border-radius: 5px;
  position: relative;
}

.event-Registration-form{
  margin-top: 0px;
  padding: 50px;
}

.event-Registration-sponsor-container{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0px;
  align-items: center;
  padding: 0;
  text-align: center;
}

.event-Registration-sponsor-wrapper{
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}

.event-Registration-sponsor-image{
  width: 20%;
  margin:0px;
  border-radius: 5px;
}

.event-Registration-details-container{
  display: flex;
  flex-direction:row;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  align-items: center;
}

.event-Registration-details-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  font-weight: bold;
}

.event-Registration-footer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color:rgba(242, 242, 242, 0.325);
  font-size: x-small;
}

.event-Registration-footer-details{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.event-Registration-poweredby{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.event-Registration-overlaybutton-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  background-color:rgba(124, 195, 155, 0.325);
  
}

.event-Registration-overlaybutton{
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.event-Registration-details-body h1{
  font-size: x-large;
}

/* ticket and pass registration page css above */








@media screen and (max-width: 991px) {
  /* Tablet styles */

  .login-card {
    background-color: #fefefe;
    padding-top: 3%;
    padding-left: 5%;
    padding-right: 5%;
    height: 93vh;
    font-size: 12px;
    width: 90%;
  }

  .main-form-container {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    align-items: center;
    justify-content: center;
    background-color: #fefefe;
    border-color: #569f51;
    padding-right: 0%;
    padding-left: 0%;
    border-radius: 0px;
    width: 90%;
    height: 90vh;




  }

  .form-container {
    /* max-height: 90vh; */
    /* padding-top: 4%; */
    padding-left: 10%;
    padding-right: 10%;
    overflow-y: scroll;
    font-size: 12px;
    padding-bottom: 0%;
    width: 100%;


  }

  .formImage-container {
    display: none;
    overflow-y: hidden;
    background-color: #569f51;
    max-width: 100%;
    padding: 10%;
    align-items: center;
    height: 20vh;
  }

  .pass-access-zones {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .pass-template-container{
    width: 100%;
  }

  .credits-overview{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 100%;
  }
  
  .credits-pie{
    width:100% ;
  }
  
  .credits-details{
    width: 100%;
  }
}



@media screen and (max-width: 767px) {
  /* Mobile styles */

  
  .App {
   
    background-color:rgb(234, 234, 234);
    padding-left: 0%;
    padding-right: 0%;

  }

  


  .login-container {

    margin-left: 0%;
    margin-right: 0%;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 0Vh;
    height: 100vh;
    width: 100%;
  }

  .login-card {
    background-color: #fefefe;
    padding-top: 20%;
    max-height: 100%;
    min-height: 50vh;
    font-size: 12px;
    margin-left: 0%;
    margin-right: 0%;
    overflow-y: scroll;
    width: 90%;
  }

  .login-card-body {
    width: 100%
  }

  .home-text {
    margin-bottom: 10vh;
    margin-top: 10vh;
  }

  .form-container {
    
    padding-left: 10%;
    padding-right: 10%;
    overflow-y: scroll;
    font-size: 12px;
    background-color: #ffffff;
    width: 100%;
    

  }

  .main-form-container {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    background-color: #fefefe;
    border-color: #569f51;
    padding-right: 0%;
    padding-left: 0%;
    border-radius: 0px;
    width: 95%;
    height: 90vh;
    align-items: center;
    justify-content: center;
  }

  .formImage-container {
    display: none;
    overflow-y: hidden;
    background-color: #569f51;
    max-width: 100%;
    padding: 10%;
    align-items: center;
    height: 0vh;
  }

  .statuses {
    display: flex;
    flex-direction: column;

  }

  .registration-form-top-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .custom-nav-tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .identity-adds-on {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .identity-adds-on-less {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }



  .card-operations-row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    justify-content: center;
  }

  .card-operations-card {
    min-width: 50%;
    margin-top: 2vh;
  }

  .documents-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  

  .document-card {
    min-width:200px;
    cursor: pointer;
    margin-top: 5px;
  }
  
  
  .document-card-inactive {
    min-width:200px;
    cursor: pointer;
    color: #aaa;
    background-color: #f2f2f2;
    margin-top: 5px;
  }

  .tag-types {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 20vh;
  }

  .card-design-main-card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 40px;
    min-height: 90vh;
  }

  .card-design-div2 {
    width: 100%;
    margin-left: 10px;
  }

  .card-design-div1 {
    width: 100%;

  }

  .ticket-input {
    width: 190px;
  }

  .neldlogo {
    width: 70%;
  }

  .neldTheme {
    width: 400px;
    text-align: center;
    font-size: 12px;
  }

  .neldAgenda {
    width: 100%
  }

  .neldAgendaContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .eventAgendaContainer {

    padding: 0%;
    min-height: 100vh;


  }

  .neldAgendaContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: fit-content;

  }

  .neldpinnitagsLogo {
    width: 50%
  }

  .pass-access-zones {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nav-icons {
    margin-left: 0rem;
    margin-right: 1rem;
    font-size: 15px;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }

  .billing-sidebar-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px;
    cursor: pointer;
  }

  .billing-sidebar {
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    width: 100%;
    
  }

  .billing-sidebar-column {
    border-right: none;
    border-bottom: 1px solid gray;
    overflow-x: scroll;
    padding-left: 20px;
    min-height: 0px;
    
  }

  .pass-template-container{
    width: 100%;
  }

  .bill-summary-dates{
    border-left: none;
  }

  .image-preview-events-form{
    max-width: 155px;
    min-width: 150px;
  }
  
  /* ticket and pass registration page css above below */

.event-Registration-container {
  position: absolute;
  top: 0;
  padding: 10px;
  padding-top: 0%;
  min-height: 100vh;
  left: 0;
  right: 0;
  background-image: url(./images/pinniTagsBG.jpg);
  background-size:cover;
  max-width: 100%;
}

.event-Registration-body{
  margin-top: 10px;
  background-color:rgba(233, 235, 235, 0.8);
  min-height: 90vh;
  border-radius: 5px;
  position: relative;
}

.event-Registration-form{
  margin-top: 0px;
  padding: 20px;
}

.event-Registration-sponsor-container{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0px;
  align-items: center;
  padding: 0;
  text-align: center;
}

.event-Registration-sponsor-wrapper{
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}

.event-Registration-sponsor-image{
  width: 50%;
  margin:0px;
  border-radius: 5px;
}

.event-Registration-details-container{
  display: flex;
  flex-direction:row;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  align-items: center;
}

.event-Registration-details-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  font-weight: bold;
}

.event-Registration-footer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color:rgba(242, 242, 242, 0.325);
  font-size: 8px;
}

.event-Registration-footer-details{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.event-Registration-poweredby{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.event-Registration-overlaybutton-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  background-color:rgba(124, 195, 155, 0.325);
  
}

.event-Registration-overlaybutton{
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.event-Registration-details-body h1{
  font-size: large;
}
.event-Registration-details-body p{
  font-size: smaller;
}
/* ticket and pass registration page css above */

}