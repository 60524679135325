.loading-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
  }
  
  .loading-animation {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 10px solid rgba(246, 151, 9, 0.3);
    border-top-color: #f1961e;
    animation: spin 1s ease-in-out infinite;
    animation-delay: 0.1s; /* Add animation delay */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add box shadow */
  }
  
  .loading-animation::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    transform: scaleY(0.4) translateZ(0);
    filter: blur(8px);
    opacity: 0.4;
    animation: pulse 2s ease-in-out infinite; /* Add pulse animation */
    animation-delay: 0.5s; /* Add animation delay */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(0.8);
      opacity: 0;
    }
  }
  