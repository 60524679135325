@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');


html {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    color: #fff;
}

body {
    min-height: 100vh;
    background-color: dodgerblue;
}

.allmodals .ant-modal-content{
    border-radius: 5px;
}

.pageA4 .ant-modal-content{
    
    width:820px;
}

.passA4 .ant-modal-content{
    
    width:820px;
    padding: 0px;
}
.overlay-modal .ant-modal-content{
    background: none;
    border: 0;
    box-shadow: none;
}

/* .ant-btn-primary:hover {
    background: #569f51;
    border-color:#569f51;
} */


